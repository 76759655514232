import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Header from '../layouts/Header';

class About extends Component {
    render() {
        // Destructure the props for easier use
        const { projectTitle } = this.props;
        const sideHeaderName = "About";
        const sideHeaderDescription = "Discover the passion that fuels our literary and theatrical endeavors. Join us in celebrating the art of storytelling through a harmonious blend of words and stagecraft.";

        return (
            <>
                {/* SECTION NAVBAR START */}
                <Navbar projectTitle={projectTitle} />
                {/* SECTION NAVBAR END */}

                <Header sideHeaderName={sideHeaderName} sideHeaderDescription={sideHeaderDescription} />

                <section className="about-section section-padding" id="section_2">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="pb-5 mb-5">
                                    <div className="section-title-wrap mb-4">
                                        <h4 className="section-title">Our story</h4>
                                    </div>

                                    <p>In crafting the narrative of "Our Story to Bookplay," we embark on a journey through the intricacies of human connection, weaving a tapestry of emotions, challenges, and triumphs. This captivating tale unfolds as two souls entwine, navigating the labyrinth of life hand in hand. From the initial serendipity that brought them together to the shared laughter echoing through the chapters, every moment is etched with the vibrancy of love's brushstroke.</p>

                                    <p>As the plot thickens, the protagonists confront trials that test the resilience of their bond, transforming the narrative into a poignant exploration of growth and commitment. Amidst the twists and turns, the characters discover the power of vulnerability and the beauty of embracing imperfections. "Our Story to Bookplay" isn't just a collection of shared memories; it's a testament to the strength of enduring love, an inspiring testament to the extraordinary journey that unfolds when two hearts coauthor the script of their lives. Let this tale be a beacon, inviting readers to reflect on their own stories, finding inspiration in the universal themes of love, resilience, and the profound beauty of shared narratives.</p>

                                    <img src={`./frontend/images/jpeg/medium-shot-young-people-recording-podcast.jpg`} className="about-image mt-5 img-fluid" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-12 col-12">
                                <div className="section-title-wrap mb-5">
                                    <h4 className="section-title">Meet podcaters</h4>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="team-thumb bg-white shadow-lg">
                                    <img src={`./frontend/images/jpeg/profile1.jpg`} className="about-image img-fluid" alt="" />

                                    <div className="team-info">
                                        <h4 className="mb-2">
                                            Taylor
                                            <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                        </h4>

                                        <span className="badge">Modeling</span>

                                        <span className="badge">Fashion</span>
                                    </div>

                                    <div className="social-share">
                                            <ul className="social-icon">
                                                <li className="social-icon-item">
                                                    <Link to="/" className="social-icon-link bi-twitter"></Link>
                                                </li>

                                                <li className="social-icon-item">
                                                    <Link to="/" className="social-icon-link bi-facebook"></Link>
                                                </li>

                                                <li className="social-icon-item">
                                                    <Link to="/" className="social-icon-link bi-pinterest"></Link>
                                                </li>
                                            </ul>
                                        </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="team-thumb bg-white shadow-lg">
                                    <img src={`./frontend/images/jpeg/profile2.jpg`} className="about-image img-fluid" alt="" />

                                    <div className="team-info">
                                        <h4 className="mb-2">
                                            William
                                            <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                        </h4>

                                        <span className="badge">Creative</span>

                                        <span className="badge">Design</span>
                                    </div>

                                    <div className="social-share">
                                        <ul className="social-icon">
                                            <li className="social-icon-item">
                                                <Link to="/" className="social-icon-link bi-twitter"></Link>
                                            </li>

                                            <li className="social-icon-item">
                                                <Link to="/" className="social-icon-link bi-facebook"></Link>
                                            </li>

                                            <li className="social-icon-item">
                                                <Link to="/" className="social-icon-link bi-pinterest"></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
                                <div className="team-thumb bg-white shadow-lg">
                                    <img src={`./frontend/images/jpeg/profile3.jpg`} className="about-image img-fluid" alt="" />

                                    <div className="team-info">
                                        <h4 className="mb-2">
                                            Chan
                                            <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                        </h4>

                                        <span className="badge">Education</span>
                                    </div>

                                    <div className="social-share">
                                            <ul className="social-icon">
                                                <li className="social-icon-item">
                                                    <Link to="/" className="social-icon-link bi-linkedin"></Link>
                                                </li>

                                                <li className="social-icon-item">
                                                    <Link to="/" className="social-icon-link bi-whatsapp"></Link>
                                                </li>
                                            </ul>
                                        </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="team-thumb bg-white shadow-lg">
                                    <img src={`./frontend/images/jpeg/profile6.jpg`} className="about-image img-fluid" alt="" />

                                    <div className="team-info">
                                        <h4 className="mb-2">
                                            Candice
                                            <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                        </h4>

                                        <span className="badge">Storytelling</span>

                                        <span className="badge">Business</span>
                                    </div>

                                    <div className="social-share">
                                        <ul className="social-icon">
                                            <li className="social-icon-item">
                                                <Link to="/" className="social-icon-link bi-twitter"></Link>
                                            </li>

                                            <li className="social-icon-item">
                                                <Link to="/" className="social-icon-link bi-facebook"></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default About;
