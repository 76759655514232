import React, { Component } from 'react';
import Navbar from '../pages/Navbar';

class Main extends Component {
    render() {
        // Destructure the props for easier use
        const { projectTitle } = this.props;
        return (
            <>
                { /* SECTION NAVBAR START */}
                <main id="main" className="site-main main">
                    <Navbar projectTitle={projectTitle} />
                </main>
                { /* SECTION NAVBAR END */}
            </>
        )
    }
}

export default Main;