import React, { Component } from 'react';
import { Link , withRouter} from 'react-router-dom';


class Navbar extends Component {
    render() {
        // Destructure the props for easier use
        const { projectTitle } = this.props;
        const { pathname } = this.props.location;

        return (
            <>
                {/* SECTION NAVBAR START */}
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand me-lg-5 me-0" to="/">
                            <img src={`./frontend/images/png/bookplay-logo.png`} className="logo-image img-fluid" alt={projectTitle} />
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                    <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className={`nav-link ${pathname === '/about' ? 'active' : ''}`} to="/about">About</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className={`nav-link ${pathname === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                                </li>
                            </ul>

                            <div className="ms-4">
                                <Link to="/" className="btn custom-btn custom-border-btn smoothscroll">Get started</Link>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* SECTION NAVBAR END */}
            </>
        );
    }
}

export default withRouter(Navbar);
