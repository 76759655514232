import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

class Home extends Component {
    render() {
        //Owl Carousel Settings
        const options = {
            loop: true,
            center: true,
            items: 6,
            margin: 15,
            autoplay: true,
            dots: true,
            autoplayTimeout: 2500,
            smartSpeed: 450,
            nav: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 4.5
                }
            }
        };

        return (
            <>
                {/* SECTION HOME START */}
                <section className="hero-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="text-center mb-5 pb-2">
                                    <h1 className="text-white">Discover Great Books</h1>
                                    <p className="text-white">Immerse yourself in the world of literature. Explore your favorite books and discover new reads.</p>
                                    <Link to="#section_2" className="btn custom-btn smoothscroll mt-3">Start exploring</Link>
                                </div>
                                
                                <div>
                                    <OwlCarousel className="owl-carousel owl-theme" {...options}>
                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile1.jpg`} className="owl-carousel-image img-fluid" alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">
                                                    Candice
                                                    <img src={`./frontend/images/png/verified.png`} className="owl-carousel-verified-image img-fluid" alt="" />
                                                </h4>

                                                <span className="badge">Storytelling</span>

                                                <span className="badge">Business</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-twitter"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-facebook"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile2.jpg`} className="owl-carousel-image img-fluid" alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">
                                                    William
                                                    <img src={`./frontend/images/png/verified.png`} className="owl-carousel-verified-image img-fluid" alt="" />
                                                </h4>

                                                <span className="badge">Creative</span>

                                                <span className="badge">Design</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-twitter"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-facebook"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-pinterest"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile3.jpg`} alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">Taylor</h4>

                                                <span className="badge">Modeling</span>

                                                <span className="badge">Fashion</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-twitter"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-facebook"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-pinterest"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile4.jpg`} className="owl-carousel-image img-fluid" alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">Nick</h4>

                                                <span className="badge">Acting</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-instagram"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-youtube"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile5.jpg`} className="owl-carousel-image img-fluid" alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">
                                                    Elsa
                                                    <img src={`./frontend/images/png/verified.png`} className="owl-carousel-verified-image img-fluid" alt="" />
                                                </h4>

                                                <span className="badge">Influencer</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-instagram"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-youtube"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="owl-carousel-info-wrap item">
                                            <img src={`./frontend/images/jpeg/profile6.jpg`} className="owl-carousel-image img-fluid" alt="" />

                                            <div className="owl-carousel-info">
                                                <h4 className="mb-2">Chan</h4>

                                                <span className="badge">Education</span>
                                            </div>

                                            <div className="social-share">
                                                <ul className="social-icon">
                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-linkedin"></Link>
                                                    </li>

                                                    <li className="social-icon-item">
                                                        <Link to="/" className="social-icon-link bi-whatsapp"></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="latest-podcast-section section-padding pb-0" id="section_2">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-12 col-12">
                                <div className="section-title-wrap mb-5">
                                    <h4 className="section-title">Lastest episodes</h4>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block d-flex">
                                    <div className="">
                                        <div className="custom-block-icon-wrap">
                                            <div className="section-overlay"></div>
                                            <Link to="/" className="custom-block-image-wrap">
                                                <img src={`./frontend/images/jpeg/11683425_4790593.jpg`} className="custom-block-image img-fluid" alt="" />

                                                    <Link to="/" className="custom-block-icon">
                                                        <i className="bi-play-fill"></i>
                                                    </Link>
                                            </Link>
                                        </div>

                                        <div className="mt-2">
                                            <Link to="/" className="btn custom-btn">
                                                Subscribe
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="custom-block-info">
                                        <div className="custom-block-top d-flex mb-1">
                                            <small className="me-4">
                                                <i className="bi-clock-fill custom-icon"></i>
                                                50 Minutes
                                            </small>

                                            <small>Episode <span className="badge">15</span></small>
                                        </div>

                                        <h5 className="mb-2">
                                            <Link to="/">
                                                Modern Vintage
                                            </Link>
                                        </h5>

                                        <div className="profile-block d-flex">
                                            <img src={`./frontend/images/jpeg/profile7.jpg`} className="profile-block-image img-fluid" alt="" />

                                                <p>
                                                    Elsa
                                                    <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                                        <strong>Influencer</strong></p>
                                        </div>

                                        <p className="mb-0">Catalyst of trends, storyteller of brands, and curator of cultural conversations.</p>

                                        <div className="custom-block-bottom d-flex justify-content-between mt-3">
                                            <Link to="/" className="bi-headphones me-1">
                                                <span>120k</span>
                                            </Link>

                                            <Link to="/" className="bi-heart me-1">
                                                <span>42.5k</span>
                                            </Link>

                                            <Link to="/" className="bi-chat me-1">
                                                <span>11k</span>
                                            </Link>

                                            <Link to="/" className="bi-download">
                                                <span>50k</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column ms-auto">
                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-heart"></i>
                                        </Link>

                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-bookmark"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12">
                                <div className="custom-block d-flex">
                                    <div className="">
                                        <div className="custom-block-icon-wrap">
                                            <div className="section-overlay"></div>
                                            <Link to="/" className="custom-block-image-wrap">
                                                <img src={`./frontend/images/jpeg/12577967_02.jpg`} className="custom-block-image img-fluid" alt="" />

                                                    <Link to="/" className="custom-block-icon">
                                                        <i className="bi-play-fill"></i>
                                                    </Link>
                                            </Link>
                                        </div>

                                        <div className="mt-2">
                                            <Link to="/" className="btn custom-btn">
                                                Subscribe
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="custom-block-info">
                                        <div className="custom-block-top d-flex mb-1">
                                            <small className="me-4">
                                                <i className="bi-clock-fill custom-icon"></i>
                                                15 Minutes
                                            </small>

                                            <small>Episode <span className="badge">45</span></small>
                                        </div>

                                        <h5 className="mb-2">
                                            <Link to="/">
                                                Daily Talk
                                            </Link>
                                        </h5>

                                        <div className="profile-block d-flex">
                                            <img src={`./frontend/images/jpeg/profile2.jpg`} className="profile-block-image img-fluid" alt="" />

                                                <p>William
                                                    <strong>Vlogger</strong></p>
                                        </div>

                                        <p className="mb-0">Chronicles life's moments through lenses, turning experiences into digital tales.</p>

                                        <div className="custom-block-bottom d-flex justify-content-between mt-3">
                                            <Link to="/" className="bi-headphones me-1">
                                                <span>140k</span>
                                            </Link>

                                            <Link to="/" className="bi-heart me-1">
                                                <span>22.4k</span>
                                            </Link>

                                            <Link to="/" className="bi-chat me-1">
                                                <span>16k</span>
                                            </Link>

                                            <Link to="/" className="bi-download">
                                                <span>62k</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column ms-auto">
                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-heart"></i>
                                        </Link>

                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-bookmark"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="topics-section section-padding pb-0" id="section_3">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-12">
                                <div className="section-title-wrap mb-5">
                                    <h4 className="section-title">Topics</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-overlay">
                                    <Link to="/" className="custom-block-image-wrap">
                                        <img src={`./frontend/images/jpeg/physician-consulting-his-patient-clinic.jpg`} className="custom-block-image img-fluid" alt="" />
                                    </Link>

                                    <div className="custom-block-info custom-block-overlay-info">
                                        <h5 className="mb-1">
                                            <Link to="/">
                                                Productivity
                                            </Link>
                                        </h5>

                                        <p className="badge mb-0">50 Episodes</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-overlay">
                                    <Link to="/" className="custom-block-image-wrap">
                                        <img src={`./frontend/images/jpeg/repairman-doing-air-conditioner-service.jpg`} className="custom-block-image img-fluid" alt="" />
                                    </Link>

                                    <div className="custom-block-info custom-block-overlay-info">
                                        <h5 className="mb-1">
                                            <Link to="/">
                                                Technician
                                            </Link>
                                        </h5>

                                        <p className="badge mb-0">12 Episodes</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-overlay">
                                    <Link to="/" className="custom-block-image-wrap">
                                        <img src={`./frontend/images/jpeg/woman-practicing-yoga-mat-home.jpg`} className="custom-block-image img-fluid" alt="" />
                                    </Link>

                                    <div className="custom-block-info custom-block-overlay-info">
                                        <h5 className="mb-1">
                                            <Link to="/">
                                                Mindfullness
                                            </Link>
                                        </h5>

                                        <p className="badge mb-0">35 Episodes</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-overlay">
                                    <Link to="/" className="custom-block-image-wrap">
                                        <img src={`./frontend/images/jpeg/delicious-meal-with-sambal-arrangement.jpg`} className="custom-block-image img-fluid" alt="" />
                                    </Link>

                                    <div className="custom-block-info custom-block-overlay-info">
                                        <h5 className="mb-1">
                                            <Link to="/">
                                                Cooking
                                            </Link>
                                        </h5>

                                        <p className="badge mb-0">12 Episodes</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="trending-podcast-section section-padding">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-12">
                                <div className="section-title-wrap mb-5">
                                    <h4 className="section-title">Trending episodes</h4>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-full">
                                    <div className="custom-block-image-wrap">
                                        <Link to="/">
                                            <img src={`./frontend/images/jpeg/27376480_7326766.jpg`} className="custom-block-image img-fluid" alt="" />
                                        </Link>
                                    </div>

                                    <div className="custom-block-info">
                                        <h5 className="mb-2">
                                            <Link to="/">
                                                Vintage Show
                                            </Link>
                                        </h5>

                                        <div className="profile-block d-flex">
                                            <img src={`./frontend/images/jpeg/profile7.jpg`} className="profile-block-image img-fluid" alt="" />

                                                <p>Elsa
                                                    <strong>Influencer</strong></p>
                                        </div>

                                        <p className="mb-0">Catalyst of trends, storyteller of brands, and curator of cultural conversations.</p>

                                        <div className="custom-block-bottom d-flex justify-content-between mt-3">
                                            <Link to="/" className="bi-headphones me-1">
                                                <span>100k</span>
                                            </Link>

                                            <Link to="/" className="bi-heart me-1">
                                                <span>2.5k</span>
                                            </Link>

                                            <Link to="/" className="bi-chat me-1">
                                                <span>924k</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="social-share d-flex flex-column ms-auto">
                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-heart"></i>
                                        </Link>

                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-bookmark"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-full">
                                    <div className="custom-block-image-wrap">
                                        <Link to="/">
                                            <img src={`./frontend/images/jpeg/27670664_7369753.jpg`} className="custom-block-image img-fluid" alt="" />
                                        </Link>
                                    </div>

                                    <div className="custom-block-info">
                                        <h5 className="mb-2">
                                            <Link to="/">
                                                Vintage Show
                                            </Link>
                                        </h5>

                                        <div className="profile-block d-flex">
                                            <img src={`./frontend/images/jpeg/profile1.jpg`} className="profile-block-image img-fluid" alt="" />

                                                <p>
                                                    Taylor
                                                    <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                                    <strong>Creator</strong>
                                                </p>
                                        </div>

                                        <p className="mb-0"> Architects of digital narratives, molding stories with pixels and passion.</p>

                                        <div className="custom-block-bottom d-flex justify-content-between mt-3">
                                            <Link to="/" className="bi-headphones me-1">
                                                <span>100k</span>
                                            </Link>

                                            <Link to="/" className="bi-heart me-1">
                                                <span>2.5k</span>
                                            </Link>

                                            <Link to="/" className="bi-chat me-1">
                                                <span>924k</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="social-share d-flex flex-column ms-auto">
                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-heart"></i>
                                        </Link>

                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-bookmark"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12">
                                <div className="custom-block custom-block-full">
                                    <div className="custom-block-image-wrap">
                                        <Link to="/">
                                            <img src={`./frontend/images/jpeg/12577967_02.jpg`} className="custom-block-image img-fluid" alt="" />
                                        </Link>
                                    </div>

                                    <div className="custom-block-info">
                                        <h5 className="mb-2">
                                            <Link to="/">
                                                Daily Talk
                                            </Link>
                                        </h5>

                                        <div className="profile-block d-flex">
                                            <img src={`./frontend/images/jpeg/profile2.jpg`} className="profile-block-image img-fluid" alt="" />

                                                <p>
                                                    William
                                                    <img src={`./frontend/images/png/verified.png`} className="verified-image img-fluid" alt="" />
                                                    <strong>Vlogger</strong></p>
                                        </div>

                                        <p className="mb-0">Chronicles life's moments through lenses, turning experiences into digital tales.</p>

                                        <div className="custom-block-bottom d-flex justify-content-between mt-3">
                                            <Link to="/" className="bi-headphones me-1">
                                                <span>100k</span>
                                            </Link>

                                            <Link to="/" className="bi-heart me-1">
                                                <span>2.5k</span>
                                            </Link>

                                            <Link to="/" className="bi-chat me-1">
                                                <span>924k</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="social-share d-flex flex-column ms-auto">
                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-heart"></i>
                                        </Link>

                                        <Link to="/" className="badge ms-auto">
                                            <i className="bi-bookmark"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/* SECTION HOME END */}
            </>
        );
    }
}

export default Home;
