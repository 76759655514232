import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Main from './frontend/components/layouts/Main';
import Footer from './frontend/components/layouts/Footer';
import Home from './frontend/components/pages/Home';
import About from './frontend/components/pages/About';
import Contact from './frontend/components/pages/Contact';

class App extends Component {
  constructor() {
    super();
    this.state = {
      projectTitle: process.env.REACT_APP_NAME,
      currentYear: ''
    };
  }

  componentDidMount() {
    this.setState({ currentYear: new Date().getFullYear() });
  }

  render() {
    const { projectTitle, currentYear} = this.state;

    return (
      <>
      <Router>        
        <Main projectTitle={projectTitle} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Footer currentYear={currentYear} />
      </Router>
      </>
    );
  }
}

export default App;
