import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';

class Contact extends Component {
    render() {
        // Destructure the props for easier use
        const { projectTitle } = this.props;
        const sideHeaderName = "Contact";
        const sideHeaderDescription = "Connect with us to share your thoughts on our captivating stories and performances. Your feedback fuels our passion for creating unforgettable literary and theatrical experiences.";

        return (
            <>
                <Header sideHeaderName={sideHeaderName} sideHeaderDescription={sideHeaderDescription} />
                
                <section className="section-padding" id="section_2">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-5 col-12 pe-lg-5">
                                <div className="contact-info">
                                    <h3 className="mb-4">We love to help you. Get in touch</h3>

                                    <p className="d-flex border-bottom pb-3 mb-4">
                                        <strong className="d-inline me-4">Phone:</strong>
                                        <span>+91 9773424748</span>
                                    </p>

                                    <p className="d-flex border-bottom pb-3 mb-4">
                                        <strong className="d-inline me-4">Email:</strong>
                                        <Link to="/">info@bookplay.in</Link> 
                                    </p>

                                    <p className="d-flex">
                                        <strong className="d-inline me-4">Location:</strong>
                                        <span>2613/3, Relief Rd, opp. Post Office, Old City, Zaveriwad, Kalupur, Ahmedabad, Gujarat 380001</span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-12 mt-5 mt-lg-0">
                                <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14687.489202956234!2d72.5905917!3d23.0284605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e844838effd7f%3A0x1ca488b47a8093a6!2sEinzigartige%20-%20Top%20Rated%20Web%20%26%20App%20Development%20Company!5e0!3m2!1sen!2sin!4v1705775308635!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy"  referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="contact-section section-padding pt-0">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="section-title-wrap mb-5">
                                    <h4 className="section-title">You know, Contact Form</h4>
                                </div>

                                <form action="#" method="post" className="custom-form contact-form" role="form">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="form-floating">
                                                <input type="text" name="full-name" id="full-name" className="form-control" placeholder="Full Name" required="" />
                                                
                                                <label for="floatingInput">Full Name</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-12"> 
                                            <div className="form-floating">
                                                <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Email address" required="" />
                                                
                                                <label for="floatingInput">Email address</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-12">
                                            <div className="form-floating">
                                                <input type="text" name="company" id="name" className="form-control" placeholder="Name" required="" />
                                                
                                                <label for="floatingInput">Company</label>
                                            </div>

                                            <div className="form-floating">
                                                <textarea className="form-control" id="message" name="message" placeholder="Describe message here"></textarea>
                                                
                                                <label for="floatingTextarea">Describe message here</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-12 ms-auto">
                                            <button type="submit" className="form-control disabled" disabled>Submit</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
