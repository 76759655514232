import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer(props) {
    return (
        <>
            {/* FOOTER SECTION START */}
            <footer className="site-footer">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                            <div className="subscribe-form-wrap">
                                <h6>Subscribe. Every weekly.</h6>

                                <form className="custom-form subscribe-form" action="#" method="get" role="form">
                                    <input type="email" name="subscribe-email" id="subscribe-email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Email Address" required="" />

                                    <div className="col-lg-12 col-12">
                                        <button type="submit" className="form-control" id="submit">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-md-0 mb-lg-0">
                            <h6 className="site-footer-title mb-3">Contact</h6>

                            <p className="mb-2"><strong className="d-inline me-2">Phone:</strong> +91 9773424748</p>

                            <p>
                                <strong className="d-inline me-2">Email:</strong>
                                <Link to="/">info@bookplay.in</Link> 
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <h6 className="site-footer-title mb-3">Download Mobile</h6>

                            <div className="site-footer-thumb mb-4 pb-2">
                                <div className="d-flex flex-wrap">
                                    <Link to="/">
                                        <img src={`./frontend/images/png/app-store.png`} className="me-3 mb-2 mb-lg-0 img-fluid" alt="" />
                                    </Link>

                                    <Link to="/">
                                        <img src={`./frontend/images/png/play-store.png`} className="img-fluid" alt="" />
                                    </Link>
                                </div>
                            </div>

                            <h6 className="site-footer-title mb-3">Follow Our Social Pages</h6>

                            <ul className="social-icon">
                                <li className="social-icon-item">
                                    <Link to="/" className="social-icon-link bi-instagram"></Link>
                                </li>

                                <li className="social-icon-item">
                                    <Link to="/" className="social-icon-link bi-twitter"></Link>
                                </li>

                                <li className="social-icon-item">
                                    <Link to="/" className="social-icon-link bi-whatsapp"></Link>
                                </li>

                                <li className="social-icon-item">
                                    <Link to="/" className="social-icon-link bi-facebook"></Link>
                                </li>

                                <li className="social-icon-item">
                                    <Link to="/" className="social-icon-link bi-youtube"></Link>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>

                <div className="container pt-5">
                    <div className="row align-items-center">

                        <div className="col-lg-2 col-md-3 col-12">
                            <Link className="navbar-brand" to="/">
                                <img src={`./frontend/images/png/bookplay-logo.png`} className="logo-image img-fluid" alt="templatemo pod talk" />
                            </Link>
                        </div>

                        <div className="col-lg-5 col-md-9 col-12">
                            <ul className="site-footer-links">

                                <li className="site-footer-link-item">
                                    <Link to="/" className="site-footer-link">Help Center</Link>
                                </li>
                                <li className="site-footer-link-item">
                                    <Link to="/" className="site-footer-link">Privacy Policy</Link>
                                </li>

                                <li className="site-footer-link-item">
                                    <Link to="/contact" className="site-footer-link">Contact Us</Link>
                                </li>

                                
                            </ul>
                        </div>

                        <div className="col-lg-5 col-12">
                            <p className="copyright-text mb-0">© Copyright 2019 - {props.currentYear} <Link to="/" className="d-inline me-2">BookPlay!</Link> | All Rights Reserved. </p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* FOOTER SECTION END */}
        </>
    )
}